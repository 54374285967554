import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import "./assets/styles/index.scss";
import "./assets/styles/override.scss";

import Wrapper from "./views/Wrapper";
import App from "./views/App";
import Login from "./views/Login";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <>
        <Router>
            <Wrapper>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/login"/>
                    </Route>
                    <Route path="/login"><Login/></Route>
                    <Route path="/app"><App/></Route>
                    <Route path="*">
                        <Redirect to="/login"/>
                    </Route>
                </Switch>
            </Wrapper>
        </Router>
    </>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
