import React from "react";
import styles from "./Wrapper.module.scss";
import { ReactComponent as Logo } from "../assets/img/logo.svg";
import { ReactComponent as AutomataIcon } from "../assets/img/automata.svg";
import { ReactComponent as TwitterIcon } from "../assets/img/twitter.svg";
import { ReactComponent as GithubIcon } from "../assets/img/github.svg";
import { ReactComponent as MediumIcon } from "../assets/img/medium.svg";

import { ToastProvider } from "react-toast-notifications";

const Wrapper: React.FC = ({children}) => {
    return (
        <ToastProvider autoDismiss={true} autoDismissTimeout={5000} placement="top-right">
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <Logo className={styles.logo}/>
                    <a className={styles.website} href="https://www.ata.network/" target="_blank">Visit Automata’s Main Website</a>
                </div>
                <div className={styles.page}>
                    {children}
                </div>
                <div className={styles.footer}>
                    <div className={styles.powered}>
                        <AutomataIcon className={styles.automata_icon}/>
                        <span className={styles.text}>Powered by Automata</span>
                    </div>
                    <div className={styles.contact}>
                        <a href="https://twitter.com/automatanetwork" target="_blank">
                            <TwitterIcon className={styles.icon}/>
                        </a>
                        <a href="https://github.com/automata-network" target="_blank">
                            <GithubIcon className={styles.icon}/>
                        </a>
                        <a href="https://medium.com/@automatanetwork" target="_blank">
                            <MediumIcon className={styles.icon}/>
                        </a>
                    </div>
                </div>
            </div>
        </ToastProvider>
    );
};

export default Wrapper;
