import { useState } from "react";

function useIsMobile(): boolean {
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 769px)").matches);

    addEventListener("resize", () => {
        setIsMobile(window.matchMedia("(max-width: 769px)").matches);
    });

    return isMobile;
}

export default useIsMobile;
