import React from "react";

import useIsMobile from "../hooks/useIsMobile";
import { ReactComponent as Logo } from "../assets/img/logo.svg";
import { ReactComponent as LoginSVG } from "../assets/img/login.svg";

import styles from "./Login.module.scss";

const Login: React.FC = () => {
    const isMobile = useIsMobile();

    return (
        <div className={styles.wrapper}>
            <div className={styles.card}>
                {isMobile ? "" : (
                    <div className={styles.header}>
                        <Logo className={styles.logo}/>
                    </div>
                )}
                <div className={styles.main}>
                    <div className={styles.detail}>
                        <LoginSVG className={styles.img}/>
                        <span className={styles.slogan}>Grab your testing tokens!</span>
                        <span className={styles.desc}>You will be required to login through twitter to use Automata faucet. Fill in your address and get free testing tokens.</span>
                    </div>
                    <a className={styles.button}
                       href={`/.auth/login/twitter?post_login_redirect_uri=${window.location.origin}/app`}>
                        <span className={styles.text}>LOGIN WITH TWITTER</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Login;
