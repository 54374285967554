import React from 'react';

import { ReactComponent as automata } from '../img/automata.svg';
import { ReactComponent as basalt } from '../img/basalt.svg';
import { ReactComponent as contextfree } from '../img/contextfree.svg';
import { ReactComponent as contextfreeNative } from '../img/contextfree-native.svg';
import { ReactComponent as ether } from '../img/ether.svg';
import { ReactComponent as granite } from '../img/granite.svg';
import { ReactComponent as limestone } from '../img/limestone.svg';
import { ReactComponent as marble } from '../img/marble.svg';
import { ReactComponent as quartzite } from '../img/quartzite.svg';

const components: any = {
  Automata: automata,
  FiniteState: automata,
  Basalt: basalt,
  ContextFree: contextfree,
  'ContextFree-Native': contextfreeNative,
  Ether: ether,
  Granite: granite,
  Limestone: limestone,
  Marble: marble,
  Quartzite: quartzite,
};

export default function iconComponent(type: string, props: any) {
  const SpecificIcon = components[type];
  return <SpecificIcon {...props} />;
}
