import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Accordion, Input, Dropdown, Button } from 'semantic-ui-react';
import React, { SyntheticEvent, useState, useEffect, ChangeEvent } from 'react';

import useIsMobile from '../hooks/useIsMobile';
import iconComponent from '../assets/styles/icons';
import { ReactComponent as Steps } from '../assets/img/steps.svg';
import { ReactComponent as Arrow } from '../assets/img/arrow.svg';
import { ReactComponent as Copy } from '../assets/img/copy.svg';
import { ReactComponent as Export } from '../assets/img/export.svg';

import styles from './App.module.scss';

const faucetApi = 'https://faucet-api.ata.network';

const App: React.FC = () => {
  const { addToast } = useToasts();
  const isMobile = useIsMobile();

  const faqInfo = [
    {
      title: 'What is a faucet?',
      desc: [
        '- A faucet is an app that gives out tiny amounts of cryptocurrencies to users over a certain period of time.',
      ],
    },
    {
      title: 'What do I have to do to claim tokens?',
      desc: [
        '- Firstly, you will be required to login to the faucet with your Twitter account for verification purposes.',
        '- Then, you can claim 10 tokens every 24 hours per submitted wallet address. Tokens will be credited within the same day with each successful request.',
        '- As a measure to prevent bots from exploiting the faucet, there is a request limit of an undisclosed amount to each unique IP address accessing it.',
      ],
    },
    {
      title: 'How long can I keep these tokens for?',
      desc: [
        '- You can keep these test tokens for an indefinite time as long as the network is up and running. Stay tuned to the latest updates on our socials for the status of all our networks.',
      ],
    },
    {
      title: 'What can I use these tokens for?',
      desc: [
        '- These tokens are meant for testing purposes only. They have no value and are mainly used for testing of new features in our test networks.',
      ],
    },
  ];
  const panels = faqInfo.map((item, index) => {
    return {
      key: `panel_${index}`,
      index,
      title: {
        icon: '',
        className: index === faqInfo.length - 1 ? 'last' : '',
        content: (
          <>
            <div className={styles.text}>{item.title}</div>
            <Arrow className={styles.arrow} />
          </>
        ),
      },
      content: {
        content: (
          <div>
            {item.desc.map((desc) => (
              <p className={styles.faq_desc}>{desc}</p>
            ))}
          </div>
        ),
      },
    };
  });
  const networks: any = {
    contextfree: {
      icon: 'ContextFree',
      label: 'ContextFree',
      tokens: {
        'ContextFree-Native': {
          symbol: 'CTX',
          icon: 'ContextFree-Native',
          label: 'ContextFree-Native',
          erc20Address: '',
          returnWalletAddress:
            'a7RSYT4AXr668NGpMC3vv7h5Jqb4HYNr4cqNt9M5H4nRrjz3b',
          amount: [10],
        },
      },
    },
    finitestate: {
      icon: 'Automata',
      label: 'FiniteState',
      tokens: {
        FiniteState: {
          symbol: 'FST',
          icon: 'Automata',
          label: 'FiniteState',
          erc20Address: '',
          returnWalletAddress:
            'atd4MYUSAwtHP7s5VVESgAuYocPqVQQ5r4vLMqyQfp8nkE8Tp',
          amount: [10],
        },
      },
    },
    ropsten: {
      icon: 'Ether',
      label: 'Ropsten Testnet',
      tokens: {
        ContextFree: {
          symbol: 'CTX',
          icon: 'ContextFree',
          label: 'ContextFree',
          erc20Address: '0x8289b901CAC48EbBB1B5cb0049d1459EA1240EF7',
          returnWalletAddress: '0x7ef99b0e5beb8ae42dbf126b40b87410a440a32a',
          amount: [10],
        },
      },
    },
    // automata: {
    //   icon: 'Automata',
    //   label: 'Automata',
    //   tokens: {
    //     Automata: {
    //       symbol: 'ATA',
    //       icon: 'Automata',
    //       label: 'Automata',
    //       erc20Address: '',
    //       returnWalletAddress: '0x7ef99b0e5beb8ae42dbf126b40b87410a440a32a',
    //       amount: [10],
    //     },
    //   },
    // },
    kovan: {
      icon: 'Ether',
      label: 'Kovan Testnet',
      tokens: {
        Basalt: {
          symbol: 'BAS',
          icon: 'Basalt',
          label: 'Basalt',
          erc20Address: '0x8b857677F3fcAa404FD2D97f398cCE9bF5Fe605e',
          returnWalletAddress: '0x7ef99b0e5beb8ae42dbf126b40b87410a440a32a',
          amount: [10],
        },
        Granite: {
          symbol: 'GRA',
          icon: 'Granite',
          label: 'Granite',
          erc20Address: '0x501b6548B6A47152Be1F1d95077516C4F3C78027',
          returnWalletAddress: '0x7ef99b0e5beb8ae42dbf126b40b87410a440a32a',
          amount: [10],
        },
        Limestone: {
          symbol: 'LIM',
          icon: 'Limestone',
          label: 'Limestone',
          erc20Address: '0x44CdB631A78b658007EB387C36C80C8Bb3Fd82A9',
          returnWalletAddress: '0x7ef99b0e5beb8ae42dbf126b40b87410a440a32a',
          amount: [10],
        },
        Marble: {
          symbol: 'MAR',
          icon: 'Marble',
          label: 'Marble',
          erc20Address: '0x45BBc2f402eABb3Fa924b2c6e3EBd83c033b3f32',
          returnWalletAddress: '0x7ef99b0e5beb8ae42dbf126b40b87410a440a32a',
          amount: [10],
        },
        Quartzite: {
          symbol: 'QUA',
          icon: 'Quartzite',
          label: 'Quartzite',
          erc20Address: '0xF72420c5EB5e0301007b792d171479fa603f2c65',
          returnWalletAddress: '0x7ef99b0e5beb8ae42dbf126b40b87410a440a32a',
          amount: [10],
        },
      },
    },
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const [address, setAddress] = useState('');
  const [network, setNetwork] = useState('contextfree');
  const [token, setToken] = useState('ContextFree-Native');
  const [tokenInfo, setTokenInfo] = useState(networks[network].tokens[token]);
  const [tokenOptions, setTokenOptions] = useState([]);
  const [amount, setAmount] = useState(10);
  const [amountOptions, setAmountOptions] = useState([]);

  const networkDropdownItem = (type: string, label: string) => {
    return (
      <div className={styles.dropdown_item_text}>
        {type
          ? iconComponent(type, { className: styles.dropdown_item_icon })
          : ''}
        <span>{label}</span>
      </div>
    );
  };
  let networkOptions: any[] = Object.keys(networks).map((key) => {
    let item = networks[key];
    return {
      key: key,
      text: networkDropdownItem(item.icon, item.label),
      value: key,
      content: networkDropdownItem(item.icon, item.label),
    };
  });

  const ellipsisAddress = (address: string) => {
    let num = isMobile ? 11 : 19;
    return address.slice(0, num) + '...' + address.slice(address.length - num);
  };

  const onNetworkChange = (e: SyntheticEvent, data: any) => {
    setNetwork(data.value);
  };

  const onTokenChange = (e: SyntheticEvent, data: any) => {
    setToken(data.value);
  };

  const onAmountChange = (e: SyntheticEvent, data: any) => {
    setAmount(data.value);
  };

  const setDefaultToken = () => {
    setToken(Object.keys(networks[network].tokens)[0]);
    setTokenOptions(
      Object.keys(networks[network].tokens).map((key: any, index: number) => {
        let item = networks[network].tokens[key];
        return {
          key: `token_${index}`,
          text: networkDropdownItem(
            item.icon,
            `(${item.symbol}) ${item.label}`
          ),
          value: key,
          content: networkDropdownItem(
            item.icon,
            `(${item.symbol}) ${item.label}`
          ),
        };
      }) as any
    );
  };

  const setDefaultAmount = () => {
    setAmount(networks[network].tokens[token].amount[0]);
    let symbol = networks[network].tokens[token].symbol;
    setAmountOptions(
      networks[network].tokens[token].amount.map(
        (amount: number, index: number) => {
          return {
            key: `amount_${index}`,
            text: networkDropdownItem('', `${amount} ${symbol}`),
            value: amount,
            content: networkDropdownItem('', `${amount} ${symbol}`),
          };
        }
      )
    );
  };

  useEffect(() => {
    setDefaultToken();
  }, [network]);

  useEffect(() => {
    setDefaultAmount();
    setTokenInfo(networks[network].tokens[token]);
  }, [token]);

  const onAddressChange = (e: ChangeEvent, data: any) => {
    setAddress(data.value);
  };

  const onErc20AddressClick = () => {
    if (tokenInfo.erc20Address) {
      window.open(
        `https://${network}.etherscan.io/token/${tokenInfo.erc20Address}`,
        '_blank'
      );
    }
  };

  const onSubmit = async () => {
    if (isSubmitting) return;
    setSubmitting(true);
    let erc20Address = tokenInfo.erc20Address;
    const reqeust = erc20Address
      ? { network, token, erc20Address, address }
      : { network, address };
    try {
      const result = await axios.post(faucetApi, reqeust);
      if (result.status >= 200 && result.status < 400) {
        const { success } = result.data;
        if (success) {
          addToast('Submitted', { appearance: 'success' });
        } else {
          const { errMsg } = result.data;
          addToast(`Error: ${errMsg}`, { appearance: 'error' });
        }
      } else {
        addToast(`Error: ${result.statusText}`, { appearance: 'error' });
      }
    } catch (error) {
      if (error.toString() === 'Error: Request failed with status code 503') {
        addToast('Error: Operation too frequent, please try again later!', {
          appearance: 'error',
        });
      } else {
        addToast(`${error}`, { appearance: 'error' });
      }
    }
    setSubmitting(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <div className={styles.slogan}>Grab your testing tokens!</div>
        <div className={styles.desc}>
          Fill in your address and get free testing tokens. Once you have them,
          go ahead and try out the following applications:
        </div>
      </div>
      <div className={styles.main}>
        {isMobile ? (
          ''
        ) : (
          <div className={styles.steps}>
            <Steps className={styles.img} />
          </div>
        )}
        <div className={styles.form}>
          <div className={styles.row}>
            <div className={styles.address_input}>
              <div className={styles.label}>Recipient’s Address:</div>
              <Input
                fluid
                placeholder={`Ex: ${
                  token === 'ContextFree-Native'
                    ? 'a7SvTrjvshEMePMEZpEkYMekuZMPpDwMNqfUx8N8ScEEQYfM8'
                    : token === 'FiniteState'
                    ? 'atd4MYUSAwtHP7s5VVESgAuYocPqVQQ5r4vLMqyQfp8nkE8Tp'
                    : '0x7ef99b0e5beb8ae42dbf126b40b87410a440a32a'
                }`}
                onChange={onAddressChange}
              />
            </div>
            <div className={styles.network_input}>
              <div className={styles.label}>Network:</div>
              <Dropdown
                fluid
                selection
                placeholder="Select Network"
                options={networkOptions}
                onChange={onNetworkChange}
                value={network}
                icon={<Arrow className={styles.dropdown_icon} />}
              />
            </div>
          </div>
          <div className={styles.row} style={{ height: '0', flex: '1' }}>
            <div className={styles.submit_wrapper}>
              <div className={styles.row}>
                <div className={styles.token_input}>
                  <div className={styles.label}>Choose your Token:</div>
                  <div className={styles.token_group}>
                    <Dropdown
                      fluid
                      selection
                      placeholder="Select Token"
                      options={tokenOptions}
                      className={styles.token_dropdown}
                      onChange={onTokenChange}
                      value={token}
                      icon={<Arrow className={styles.dropdown_icon} />}
                    />
                    <Export
                      className={styles.contract_link}
                      title="Contract Details"
                      onClick={onErc20AddressClick}
                    />
                  </div>
                </div>
                <div className={styles.amount_input}>
                  <div className={styles.label}>Choose Amount:</div>
                  <Dropdown
                    fluid
                    selection
                    placeholder="Select Amount"
                    options={amountOptions}
                    onChange={onAmountChange}
                    value={amount}
                    icon={<Arrow className={styles.dropdown_icon} />}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <Button
                  loading={isSubmitting}
                  className={styles.submit_btn}
                  onClick={onSubmit}
                >
                  SUBMIT TOKENS TO MY WALLET
                </Button>
              </div>
            </div>
            <div className={styles.disclaimer_wrapper}>
              <div className={styles.label}>Disclaimer:</div>
              <div className={styles.disclaimer}>
                <p>
                  You can get up to 10 units of your desired token on a limit of
                  24hrs. If you ever need a higher amount of any of the tokens
                  offered by our Faucet service, please contact us at{' '}
                  <a href="https://discord.com/invite/dCCjWK86TF">
                    our Discord
                  </a>{' '}
                  with the desired quantity and usage.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.faq}>
          <Accordion fluid defaultActiveIndex={0} panels={panels} />
        </div>
        <div className={styles.wallet}>
          <div className={styles.title}>Return Testnet Tokens:</div>
          <div className={styles.desc}>
            Feel free to return the tokens after you use them :)
          </div>
          <div className={styles.info}>
            {iconComponent(token, { className: styles.icon })}
            <div className={styles.address_wrapper}>
              <div
                className={styles.name}
              >{`(${tokenInfo.symbol}) ${tokenInfo.label} Wallet`}</div>
              <div className={styles.address}>
                {ellipsisAddress(tokenInfo.returnWalletAddress)}
              </div>
            </div>
            <CopyToClipboard
              text={tokenInfo.returnWalletAddress}
              onCopy={() => addToast('Copied', { appearance: 'success' })}
            >
              <Copy className={styles.copy_icon} />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
